@import url('https://fonts.googleapis.com/css?family=Pacifico');
.App {
  margin-top: 1rem;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dinner_image {
  margin-top: -1rem;
  width: 100%;
}

.gray-text {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: dimgray;
}

.small-font {
  font-size: 0.7rem;
}

.medium-font {
  font-size: 1.2rem;
}

.ui.circular.button {
  background-color: transparent;
  border: solid 1px;
}

.infinite-scroll-component {
  padding: 10px;
}


.ui.card.tweet-card {
  width: 70%;
  margin-bottom: 2rem;
  margin-left: 15%;
}
.red {
  color: red;
}

.green {
  color: green;
}

.display-inline {
  display: inline-flex;
}

.hide-button {
  background-color: transparent;
  border: none;
  width: 15rem;
  height: 3rem;
}

.logo-70 {
  margin-top: 2rem;
  width: 70%;
}

.test-kit-button {
  border-radius: 25px !important;
  width: 16rem;
}

.margin-top-20 {
  margin-top: 20% !important;
}

.blue {
  width: fit-content;
  border: solid 1px #2185d0;
  border-radius: 5px;
}

.font-3 {
  font-size: 2rem;
}

.text-wrap {
  word-wrap: break-word;
}

.f-1 { font-size: 1.2rem !important; font-style: bold; color: white; font-weight: bold; }
.f-1-5 { font-size: 1.5rem !important; }
.mt-neg-5 {
  margin-top: -0.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
